.important {
  padding: 50px 90px;
  &__inner {

  }
  &__title {
    font-size: 45px;
    color: $color-accent-bright;
    margin-bottom: 35px;
  }
  &__list {
    display: flex;
    justify-content: space-between;
  }
  &__item {
    max-width: 48%;
    width: 100%;
    color: #ffffff;
    padding-left: 30px;
    position: relative;
    &::before {
      content: '';
      display: block;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      background-color: $color-accent-bright;
      position: absolute;
      left: 0; top: 0.3em;
    }
  }
}


@media (max-width: 1024px) {
  .important {
    background: none;
    &__title {
      color: $color-accent;
      margin-bottom: 40px;
      text-align: center;
    }
    &__list {
      flex-direction: column;
    }
    &__item {
      max-width: none;
      color: #222122;
      font-size: 20px;
      margin-bottom: 50px;
      &::before {
        background-color: $color-accent;
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199px) {

}
@media (min-width: 768px) and (max-width: 991px) {

}
@media (max-width: 767px) {
  .important {
    padding: 90px 0;
    padding-bottom: 75px;
    &__title {
      font-size: $fz-local-section-title-before-mobile;
      margin-bottom: 35px;
      text-align: left;
      color: #000000;
    }
    &__item {
      padding-left: 20px;
      margin-bottom: 40px;
      &::before {
        width: 10px;
        height: 10px;
      }
    }
  }
}
@media (max-width: 480px) {
  .important {
    &__title {
      font-size: $fz-local-section-title-mobile;
    }
  }
}
@media (max-width: 320px) {

}