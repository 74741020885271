.dealer {
  padding: 100px 0;
  color: #222122;
  &__inner {

  }
  &__title {
    margin-bottom: 90px;
    color: #222122;
  }
  &__info {
    margin-bottom: 70px;
    &.hidden {
      display: none;
    }
  }
  &__city-name {
    font-size: 45px;
    color: $color-accent;
    margin-bottom: 50px;
  }
  &__box-container {
    display: flex;
    align-items: flex-start;
  }
  &__box {
    display: flex;
    align-items: flex-start;
    margin-right: 135px;
  }
  &__box-title {
    font-size: 28px;
    font-weight: 500;
    margin-right: 20px;
  }
  &__box-text {
    margin-bottom: 10px;
  }
  &__column {
    display: flex;
    flex-direction: column;
    padding-top: 5px;
  }
  &__map {
    position: relative;
  }
}

@media (max-width: 1024px) {
  .dealer {
    &__city-name {
      font-size: 40px;
    }
    &__box {
      margin-right: 0;
      width: 50%;
    }
    &__box-text {
      font-size: 20px;
    }
    
  }
}
@media (min-width: 992px) and (max-width: 1199px) {

}
@media (min-width: 768px) and (max-width: 991px) {

}
@media (max-width: 767px) {
  .dealer {
    padding: 100px 0 50px;
    &__box-container {
      flex-direction: column;
    }
    &__box {
      flex-direction: column;
      margin-bottom: 30px;
      width: auto;
    }
    &__box-title {
      margin-bottom: 10px;
    }
    &__city-name {
      font-size: $fz-local-section-title-before-mobile;
    }
    &__map {
      width: calc(100% + 40px);
      margin-left: -20px;
    }
  }
}
@media (max-width: 480px) {
  .dealer {
    &__city-name {
      font-size: $fz-local-section-title-mobile;
    }
    &__box-title {
      font-size: 20px;
    }
    &__box-text {
      font-size: 18px;
    }
  }
}
@media (max-width: 320px) {

}