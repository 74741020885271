$mob-padding-sum: 40px;
.hydraulic {
  padding: 100px 0;
  &__inner {

  }
  &__title {
    color: #222122;
  }
  &__main {
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px;
  }
  &__content {}
  &__content-title {
    font-size: 45px;
    max-width: 460px;
    margin-bottom: 50px;
  }
  &__features-item {
    color: #222122;
    & + & {
      margin-top: 50px;
    }
    display: flex;
  }
  &__features-icon {
    display: block;
    width: 50px;
    min-width: 50px;
    height: 50px;
    margin-right: 20px;
  }
  &__features-list {
    max-width: 470px;
  }
  &__img {
    height: 100%;
    max-width: 48%;
    // width: 48%;
  }
  &__services {

  }
}

.services {
  &__list {
    display: flex;
    justify-content: space-between;
  }
  &__item {
    box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    background-color: #222122;
    padding: 45px 25px;
    // max-width: 410px;
    width: 100%;
    text-align: center;
    color: #ffffff;
    max-width: calc(33% - 20px);
  }
  &__title {
    color: $color-accent-bright;
    font-weight: 700;
    font-size: 60px;
    margin-bottom: 30px;
  }
  &__subtitle {
    font-size: 30px;
    margin-bottom: 20px;
  }
  &__text {
    // line-height: 1.15;
  }
}

@media (max-width: 1024px) {
  .hydraulic {
    &__content-title {
      font-size: 40px;
    }
    &__img {
      height: 100%;
      max-width: none;
      width: 52.5%;
      margin-right: -30px;
    }
  }
  .services {
    &__list {}
    &__item {
      padding: 35px 20px;
      padding-bottom: 50px;
    }
    &__title {
      font-size: 50px;
    }
    &__subtitle {
      margin-bottom: 15px;
      font-weight: 500;
      font-size: 20px;
    }
    &__text {}
  }
}
@media (max-width: 767px) {
  .hydraulic {
    padding-bottom: 50px;
    &__main {
      flex-direction: column;
    }
    &__features-list {
      max-width: none;
      margin-bottom: 50px;
    }
    &__features-item {
      & + & {
        margin-top: 35px;
      }
    }
    &__features-icon {
      width: 40px;
      min-width: 40px;
      height: 40px;
    }
    &__content-title {
      font-size: $fz-local-section-title-before-mobile;
      margin-bottom: 35px;
    }
    &__img {
      max-width: none;
      margin-right: 0;
      margin-left: -20px;
      width: calc(100% + 40px);
      & > img {
        max-width: none;
        width: 100%;
      }
    }
  }

  .services {
    &__list {
      flex-direction: column;
    }
    &__item {
      max-width: none;
      width: calc(100% + 40px);
      margin-bottom: 20px;
      margin-left: -20px;
      border-radius: 0;
    }

  }
}
@media (max-width: 480px) {
  .hydraulic {
    &__content-title {
      font-size: $fz-local-section-title-mobile;
    }
  }
  .services {
    &__item {
      padding: 15px 15px 30px;
      margin-bottom: 10px;
    }
    &__title {
      font-size: 25px;
      margin-bottom: 10px;
    }
    &__subtitle {
      font-size: 17px;
      margin-bottom: 8px;
    }
    &__text {
      font-size: 15px;
      color: $color-gray;
    }
  }
}
@media (max-width: 320px) {

}