.catalog {
  padding: 100px 0;
  &__title {
    margin-bottom: 55px;
  }
  &__tabs {
    margin-bottom: 80px;
    display: flex;
  }
  &__tab {
    max-width: 225px;
    cursor: pointer;
    color: #ffffff;
    border: none;
    background-color: transparent;
    & + & {
      margin-left: 150px;
    }
    &.active {
      color: $color-accent-bright;
    }
  }
  &__list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    &.hidden {
      display: none;
    }
  }
  &__card {
    flex-basis: calc(25% - 23px);
    max-width: calc(25% - 23px);
    &:not(:nth-of-type(4n)) {
      margin-right: 30px;
    }
    &:last-child {
      margin-right: auto;
      margin-left: 0;
    }
  }
  &__more {
    color: $color-accent-bright;
    margin: 0 auto;
    text-align: center;
    & > a:hover {
      text-decoration: underline dotted;
    }
  }
}

.product-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #222122;
  background-color: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 50px;
  &__img {
    width: 100%;
    margin-bottom: 15px;
  }
  &__title, &__description, &__price {
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
  }
  &__title {
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 5px;
  }
  &__description {
    font-size: 18px;
    margin-bottom: 25px;
    flex-grow: 1;
  }
  &__price {
    font-size: 35px;
    font-weight: 700;
    margin-bottom: 15px;
    &::after {
      content: '';
      display: inline-block;
      width: 0.75em;
      height: 0.7em;
      background: no-repeat center / contain;
      background-image: url(../img/icon/rouble.svg);
    }
  }
  &__btn {
    width: 100%;
    border-radius: 0;
  }
}


@media (max-width: 1024px) {
  .catalog {
    &__tabs {
      // justify-content: space-between;
    }
    &__tab + &__tab {
      margin: 0;
    }
    &__list {
      // justify-content: flex-start;
    }
    &__card {
      flex-basis: calc(33.333% - 20px);
      max-width: calc(33.333% - 20px);
      &:not(:nth-of-type(4n)) {
        margin-right: 0;
      }
      &:not(:nth-of-type(3n)) {
        margin-right: 30px;
      }
      &:last-child {
        margin-right: auto;
        margin-left: 0;
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199px) {

}
@media (min-width: 768px) and (max-width: 991px) {

}
@media (max-width: 767px) {
  .catalog {
    &__card {
      flex-basis: calc(50% - 20px);
      max-width: calc(50% - 20px);
      &:not(:nth-of-type(3n)) {
        margin-right: 0;
      }
      &:not(:nth-of-type(2n)) {
        margin-right: 40px;
      }
      &:last-child {
        margin-right: auto;
        margin-left: 0;
      }
    }
  }
}
@media (max-width: 480px) {
  .catalog {
    &__tabs {
      overflow: scroll;
    }
    &__tab {
      & + & {
        margin-left: 50px;
      }
    }
    &__list {
      flex-direction: column;
    }
    &__card {
      flex-basis: 100%;
      max-width: 100%;
      &:not(:nth-of-type(2n)) {
        margin-right: 0;
      }
      &:last-child {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
  .product-card {
    &__title {
      font-size: 25px;
    }
    &__description {
      font-size: 16px;
    }
    &__price {
      font-size: 35px;
    }
  }
}
@media (max-width: 320px) {

}