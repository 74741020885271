.rollers {
  padding: 70px 0 100px;
  &__inner {

  }
  &__main {
    display: flex;
    justify-content: space-between;
  }
  &__content {
    padding-top: 30px;
    margin-right: 65px;
  }
  &__title {
    margin-bottom: 90px;
    white-space: nowrap;
  }
  &__content-title {
    font-size: 45px;
    color: $color-accent-bright;
    margin-bottom: 45px;
  }
  &__features-list {
    max-width: 390px;
  }
  &__features-item {
    margin-bottom: 50px;
    color: #ffffff;
    position: relative;
    padding-left: 30px;
    &::before {
      content: '';
      position: absolute;
      left: 0; top: 0.4em;
      display: inline-block;
      width: 15px;
      height: 15px;
      background-color: $color-accent-bright;
      border-radius: 50%;
      margin-right: 15px;
    }
  }
}

@media (max-width: 1280px) {
  .rollers {
    &__title {
      white-space: normal;
    }
    &__img {
      min-width: 40%;
      width: 40%;
    }
  }
}

@media (max-width: 1024px) {
  .rollers {
    &__main {
      align-items: center;
      justify-content: flex-start;
    }
    &__content {
      padding-top: 0;
      margin-right: 20px;
    }
    &__title {

    }
    &__img {
      margin-left: -10%;
    }
  }
}
@media (min-width: 992px) and (max-width: 1199px) {

}
@media (min-width: 768px) and (max-width: 991px) {

}
@media (max-width: 767px) {
  .rollers {
    &__title {

    }
    &__content-title {
      font-size: $fz-local-section-title-before-mobile;
    }
    &__main {
      align-items: flex-end;
    }
  }
}
@media (max-width: 700px) {
  .rollers {
    &__main {
      flex-direction: column;
      align-items: flex-start;
    }
    &__img {
      width: 70%;
      margin-left: auto;
      margin-right: 0;
    }
  }
}
@media (max-width: 480px) {
  .rollers {
    &__content-title {
      font-size: $fz-local-section-title-mobile;
    }
    &__features-list {
      margin-bottom: 50px;
    }
    &__features-item {
      margin-bottom: 20px;
      padding-left: 20px;
      &::before {
        width: 10px;
        height: 10px;
      }
    }
    &__img {
      width: calc(100% + 40px);
      margin-left: -20px;
    }
  }
}
@media (max-width: 320px) {

}