.scheme-large {
  padding-bottom: 100px;
  &__inner {}
  &__img {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .scheme-large {
    padding-bottom: 50px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {

}
@media (min-width: 768px) and (max-width: 991px) {

}
@media (max-width: 767px) {
  .scheme-large {
    padding: 0;
    &__img {
      max-width: none;
      width: calc(100% + 40px);
      margin-left: -20px;
      // margin-right: -20px;
    }
  }
}
@media (max-width: 480px) {

}
@media (max-width: 320px) {

}