.header {
  padding: 20px 0;

  &.fixed {
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
    right: 0;
    background-color: #ffffff;
    transition: .25s;
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.6);
  }
  // &.out {
  //   transform: translateY(-100%);
  //   box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0);
  // }
  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__inner-mobile {
    display: none;
    // padding: 30px;
  }
  &__box {
    display: flex;
    align-items: center;
    &--contacts {
      display: none;
    }
    &--nav {
      // width: 100%;
      flex-grow: 1;
      padding-left: 30px;
    }
  }
  &__logo {}
  &__geo {
    margin-left: 38px;
  }
  &__nav {
    width: 100%;
  }
  &__menu {
    width: 100%;
  }
  &__tel {
    margin-left: 20px;
    white-space: nowrap;
  }
  &__tel-link {}
  &__socials {
    margin-left: 20px;
  }
  // & .contacts__row {
  //   display: none;
  // }
}

.logo {
  max-width: 170px;
  &--mobile {
    max-width: 240px;
  }
  &__link {}
  &__img {}
}
.geo {
  &__link {
    white-space: nowrap;
    font-size: 18px;
    display: inline-block;
    color: $color-accent;
    transition: text-decoration-color .25s;
    text-decoration: underline;
    text-decoration-color: transparent;
    &:hover {
      text-decoration-color: $color-accent;
    }
    &::before, &::after {
      content: '';
      display: inline-block;
      width: 20px;
      height: 20px;
      background: no-repeat center / contain;
      background-image: url('../img/icon/geo.svg');
      margin-right: 5px;
    }
    &::after {
      display: none;
      background-image: url('../img/icon/map-mark.svg');
      width: 30px;
      height: 40px;
      margin-right: 0;
      margin-left: 10px;
    }
  }
}
.menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__item {
    font-size: 18px;
    white-space: nowrap;
    color: #000000;
    transition: color .25s;
    &:hover {
      color: $color-accent;
    }
  }
  &__link {}
}
.socials {
  display: flex;
  align-items: center;
  // margin-left: 40px;
  &__link {
    // width: 20px;
    // height: 20px;
    // display: block;
    // min-width: 20px;
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
    & .social-icon-svg {
      // width: 20px;
      // height: 20px;
    }

  }
  &__icon {
    width: 20px;
    height: 20px;
    // min-width: 20px;
    // min-height: 20px;
  }
}
.burger {
  width: 60px;
  cursor: pointer;
  position: relative;
  z-index: 11;
  transition: .25s;
  &.active {
    transform: rotate(90deg);
    transform-origin: center;
  }
  &.active > span {
    background-color: $color-accent-bright;
  }
  & > span {
    display: block;
    background-color: $color-accent;
    height: 13px;
    border-radius: 5px;
    margin-bottom: 6px;
  }
}

@media (max-width: 1365px) {
  .menu {
    flex-wrap: wrap;
    &__item {
      flex-basis: 33%;
      max-width: 33%;
      text-align: center;
      margin-bottom: 10px;
    }
  }
}
@media (max-width: 1024px) {
  .header {
    &__inner {
      // display: none;
      position: fixed;
      z-index: 10;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: linear-gradient(128deg, #434343 -25%, #000000 114%);

      padding: 0 30px;
      padding-bottom: 20px;
      flex-direction: column;
      padding-top: 40px;
      padding-right: 40px;
      transform: translateX(-100%);
      transition: .25s;
      overflow-y: scroll;
      &.active {
        transform: translateX(0);
        // display: flex;
      }
      &.active .header__box--contacts {
        display: flex;
      }
    }
    &__inner-mobile {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__logo {
      display: none;
    }
    &__logo.logo--mobile {
      display: block;
    }
    &__box {
      &--center {
        align-self: flex-end;
      }
      &--nav {
        align-self: flex-start;
        flex-direction: column;
        margin-top: 40px;
      }
    }
    &__socials {
      // margin-left: 0;
    }
    &__geo {}
    &__tel {
      display: none;
    }
    &__socials {
      display: none;
    }
    /* CONTACTS */
    & .contacts__row {
      color: #ffffff;
      flex-wrap: wrap;
    }
    & .contacts__box {
      margin-right: 120px;
      &:last-child {
        margin-right: 0;
      }
    }
    & .contacts__item {
      white-space: nowrap;
      margin-bottom: 25px;
    }
    & .contacts__icon {
      min-width: 35px;
      min-height: 35px;
      width: 35px;
      height: 35px;
    }
    & .socials__link {
      min-width: 35px;
      min-height: 35px;
      width: 35px;
      height: 35px;
    }    
    & .social-icon-svg {
      fill: #ffffff;
      &:hover {
        fill: $color-accent-bright;
      }
    }
    /* ======== */
  }
  .geo__link {
    font-size: 25px;
    &::before {
      display: none;
    }
    &::after {
      display: inline-block;
    }
  }
  .menu {
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    &__item {
      flex-basis: auto;
      max-width: none;
      text-align: left;
      font-size: 40px;
      color: #ffffff;
      margin-bottom: 50px;
      margin-left: 0;
      &:hover {
        color: $color-accent-bright;
      }
    }
  }
  .menu {
    &__item {
      // flex-basis: 33%;
      // max-width: 33%;
      // text-align: center;
      // margin-bottom: 10px;
    }
  }
}
@media (min-width: 992px) and (max-width: 1199px) {

}
@media (min-width: 768px) and (max-width: 991px) {

}
@media (max-width: 767px) {
  .header {
    & .contacts__row {
      flex-direction: row;
    }
    & .contacts__item {
      margin-bottom: 5px;
    }
    & .contacts__box {
      margin-bottom: 15px;
    }
  }
  .menu {
    &__item {
      font-size: 25px;
      margin-bottom: 30px;
    }
  }
}
@media (max-width: 480px) {
  .header {
    &__inner {
      padding-top: 20px;
    }
    &__logo {
      max-width: 122px;
    }
    &__call {
      width: 30px;
      height: 30px;
    }
    & .contacts__box {
      margin-right: 0;
    }
    .geo {
      &__link {
        font-size: 22px;
        &::after {
          height: 30px;
        }
      }
    }
  }
  .burger {
    width: 30px;
    & > span {
      height: 7px;
      margin-bottom: 3.5px;
    }
  }
}
@media (max-width: 320px) {

}