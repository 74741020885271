body {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: 400;
  &.lock {
    overflow: hidden;
  }
}
.container {
  max-width: 1360px;
  width: 100%;
  margin: 0 auto;
  padding-left: $padding-container;
  padding-right: $padding-container;
}
.gradient-bg {
  background: linear-gradient(128deg, #434343 -25%, #000000 114%);
}
.red-text {
  color: $color-accent;
}
.red-bright-text {
  color: $color-accent-bright;
}
.nowrap {
  white-space: nowrap;
}
.social-icon-svg {
  transition: .25s;
  &:hover {
    fill: $color-accent-bright;
  }
}
.rouble-red {
  &::after {
    content: '';
    display: inline-block;
    background: no-repeat center / contain;
    background-image: url('../img/icon/rouble-red.svg');
    height: 0.72em;
    width: 1em;
  }
}

.btn {
  font-size: 25px;
  padding: 12px 38px;
  border: none;
  background-color: $color-accent;
  color: #ffffff;
  cursor: pointer;
  border-radius: 5px;
  border: 1.5px solid $color-accent;
  transition-duration: .15s;
  // transition-property: background-color, color;
  &:hover {
    background-color: $color-accent-bright;
    border-color: $color-accent-bright;
  }
  &:active {
    background-color: #E00A00;
    border-color: #E00A00;
  }
  &--trsprt {
    border: 1.5px solid #000000;
    background-color: transparent;
    color: #000000;
    &:hover {
      background-color: transparent;
      color: $color-accent-bright;
      border-color: $color-accent-bright;
    }
    &:active {
      background-color: transparent;
      color: $color-accent;
      border-color: $color-accent;
      
    }
  }
}

.section-title {
  font-size: 60px;
  color: #ffffff;
  margin-bottom: 90px;
}

.svg-map {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.svg-map-dot {
  fill: #FF0C00;
  cursor: pointer;
  &.hidden {
    display: none;
  }
}
.svg-map-region {
  // fill: red;
  fill: transparent;
  transition: .25s;
  stroke: none;
  &:hover {
    fill: rgba(255, 255, 255, 0.3);
    cursor: pointer;
  }
}


@media (max-width: 1024px) {
  body {
    font-size: 18px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {

}
@media (min-width: 768px) and (max-width: 991px) {

}
@media (max-width: 767px) {
  .section-title {
    font-size: 45px;
    margin-bottom: 35px;
  }
  .container {
    padding-left: $padding-container-mobile;
    padding-right: $padding-container-mobile;
  }
}
@media (max-width: 480px) {
  body {
    font-size: 16px;
  }
  .section-title {
    font-size: 30px;
  }
}
@media (max-width: 320px) {

}