.characteristics {
  padding: 100px 0;
  &__inner {}
  &__title {}
  &__main {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    color: #ffffff;
    margin-bottom: 110px;
  }
  &__content {
    // max-width: 50%;
    max-width: 410px;
  }
  &__product-title {
    font-size: 45px;
    margin-bottom: 50px;
  }
  &__features-list {}
  &__features-item {
    margin-bottom: 50px;
    position: relative;
    padding-left: 30px;
    &::before {
      content: '';
      position: absolute;
      left: 0; top: 0.4em;
      display: inline-block;
      width: 15px;
      height: 15px;
      background-color: $color-accent-bright;
      border-radius: 50%;
      margin-right: 15px;
    }
  }
  &__img {
    width: 50%;
    & > img {
      // max-width: 630px;
    }
  }
  &__list {
    margin-bottom: 90px;
  }
  &__slider {
    overflow-x: hidden;
    // height: 400px;
    // margin-right: 20px;
    width: 100%;
  }
  &__slide {

  }
  &__slider-img {}
}

.char-list {
  display: flex;
  // align-items: stretch;
  &__column {
    border-radius: 10px;
    overflow: hidden;
    max-width: 50%;
    width: 100%;
    border-collapse: collapse;
    &:nth-of-type(2) {
      margin-left: 30px;
    }
  }
  &__row {
    background-color: #ffffff;
    font-weight: 500;
    font-size: 18px;
    height: 60px;
    &:nth-of-type(2n) {
      background-color: $color-gray-lite;
    }
    &:not(:last-child) {
      border-bottom: 5px solid #303030;
    }
  }
  &__title, &__unit, &__value {
    text-align: center;
  } 
  &__title {
    // padding-top: 15px;
    // padding-bottom: 15px;
    padding-left: 30px;
    text-align: left;
    width: auto;
    color: $color-accent;
    & > span {
      max-width: 220px;
      display: inline-block;
      // white-space: normal;
    }
  }
  &__unit {
    text-align: center;
  }
  &__value {
    padding-left: 85px;
    padding-right: 20px;
  }
}

@media (max-width: 1439px) {
  .characteristics {
    &__slider {
      // height: 320px;
    }
    &__slider-img {
      // height: 320px;
      height: 100%;
    }
  }
}
@media (max-width: 1024px) {
  .characteristics {
    &__inner {}
    &__product-title {
      font-size: 40px;
    }
    &__features-list {
      max-width: 370px;
    }
    &__img {
      width: 52.5%;
      margin-right: -30px;
      & > img {
        width: 100%;
      }
    }
    &__slider-img {
      height: 255px;
    }
  }
  .char-list {
    &__title {
      font-size: 15px;
    }
    &__value {
      padding-left: 55px;
      padding-right: 20px;
    }
  }
}
// @media (min-width: 992px) and (max-width: 1199px) {

// }
// @media (min-width: 768px) and (max-width: 991px) {

// }
@media (max-width: 920px) {
  .characteristics {
    &__main {
      flex-direction: column;
    }
    &__content {
      max-width: none;
      width: 100%;
    }
    &__features-list {
      max-width: none;
    }
    &__img {
      width: 100%;
      margin-right: 0;
    }
  }
}


@media (max-width: 767px) {
  .characteristics {
    padding: 50px 0;
    &__product-title {
      font-size: $fz-local-section-title-before-mobile;
      margin-bottom: 30px;
    }
    &__features-item {
      margin-bottom: 20px;
    }
    &__features-list {
      margin-bottom: 50px;
    }
    &__main {
      margin-bottom: 50px;
    }
  }
  .char-list {
    flex-direction: column;
    margin-bottom: 50px;
    &__column {
      table-layout: fixed;
      &:first-child {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        margin-bottom: 5px;
      }
      &:last-child {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        margin: 0;
      }
      max-width: 100%;
    }
    &__row {
      height: 70px;
    }
    &__title {
      font-size: 15px;
      width: 50%;
      // padding-left: 10px;
      padding-right: 10px;
      width: 60%;
    }
    &__unit {
      width: 5%;
    }
    &__value {
      padding-left: 40px;
      padding-right: 20px;
      white-space: nowrap;
      // max-width: 15%;
      // min-width: 15%;
      // width: 15%;
      width: 150px;
      // padding-left: 15px;
      // padding-right: 15px;
    }
  }
}
@media (max-width: 480px) {
  .characteristics {
    &__product-title {
      font-size: $fz-local-section-title-mobile;
    }
    &__features-item {
      padding-left: 20px;
      &::before {
        width: 10px;
        height: 10px;
      }
    }
  }
  .char-list {
    &__title, &__value {
      font-size: 15px;
    }
    &__title {
      padding-left: 10px;
      width: 70%;
    }
    &__unit {
      // width: 8%;
      width: 35px;
    }
    &__value {
      // width: 22%;
      width: 100px;
      padding-left: 20px;
    }
    // &__unit {}
  }
}
@media (max-width: 320px) {

}