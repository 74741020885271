.popup {
  background-color: rgba(84, 84, 84, 0.35);
  position: fixed;
  z-index: 15;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  &.fade-in {
    animation: fadeIn .35s ease;
  }
  &.fade-out {
    animation: fadeOut .35s ease;
  }
  &.active {
    display: block;
  }
  &__inner {
    padding: 60px 50px 42px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 500px;
    width: 100%;
    background-color: #ffffff;
    border-radius: 10px;
    // outline: 1px solid red;
  }
  &--city &__inner {
    max-width: 800px;
  }
  &__title {
    font-size: 40px;
    text-align: center;
    margin-bottom: 50px;
    & > span {
      font-weight: 500;
    }
  }
  &__form {}
  &__policy {
    font-size: 13px;
    color: #D0D0D0;
    text-align: center;
    max-width: 300px;
    margin: 0 auto;
  }
  &__btns {
    display: flex;
    justify-content: space-between;
  }
  &__btn {
    flex-basis: 49%;
    width: 49%;
  }
  &__city-list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
  }
  &__city-item {
    flex-basis: 33%;
    width: 33%;
    transition: .25s;
    margin-bottom: 20px;
    &:hover {
      color: $color-accent;
    }
  }
  &__input-wrapper {
    position: relative;
    // margin-top: 40px;
    &::after {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      right: 30px;
      top: calc(50% - 10px);
      position: absolute;
      background: no-repeat center / cover;
      background-image: url('../img/icon/search.svg');
    }
  }
  &__input {
    max-width: none;
    width: 100%;
    padding: 13px 30px;
    border: 1.5px solid #D0D0D0;
    border-radius: 5px;
    &::placeholder {
      font-size: 18px;
      line-height: 1.2;
      color: rgb(208, 208, 208);
      transition: .25s;
    }
    &:focus::placeholder {
      color: rgba(208, 208, 208, 0);
    }
  }
}

.request-form {
  margin-bottom: 10px;
  &__input {
    padding: 14px 30px;
    margin-bottom: 30px;
    font-size: 18px;
    border: 1.5px solid #D0D0D0;
    border-radius: 5px;
    max-width: none;
    width: 100%;
    &::placeholder {
      font-size: 18px;
      line-height: 1.2;
      color: rgb(208, 208, 208);
      transition: .25s;
    }
    &:focus::placeholder {
      color: rgba(208, 208, 208, 0);
    }
  }
  &__btn {
    margin: 0 auto;
    display: block;
  }
}

@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity: 1;}
}
@keyframes fadeOut {
  from {opacity: 1;}
  to {opacity: 0;}
}

@media (max-width: 900px) {
  .popup {
    &__title {
      font-size: 30px;
      margin-bottom: 35px;
    }
  }
  .request-form {
    &__input {
      margin-bottom: 20px;
    }
  }
}
@media (max-width: 767px) {
  .popup {
    &__city-list {
      margin-bottom: 30px;
    }
    &__city-item {
      flex-basis: 50%;
      width: 50%;
      margin-bottom: 10px;
    }
    &__input-wrapper {
      &::after {
        right: 10px;
      }
    }
    &__input {
      // font-size: 16px;
    }
  }
}
@media (max-width: 600px) {
  .popup {
    &__inner {
      width: calc(100% - 40px);

    }
  }
}

@media (max-width: 480px) {
  .popup {
    &__inner {
      padding-left: 10px;
      padding-right: 10px;
    }
    &__title {
      // white-space: nowrap;
      // margin-bottom: 15px;
      font-size: 25px;
    }
    &__btns {
      flex-direction: column;
    }
    &__btn {
      flex-basis: 100%;
      width: 100%;
      margin-bottom: 10px;
      font-size: 20px;
    }
  }
  .request-form {
    &__input {
      padding: 10px 20px;
      font-size: 16px;
      &::placeholder {
        font-size: 16px;
      }
    }
    &__btn {
      font-size: 20px;
    }
  }
}