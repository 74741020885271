.delivery {
  padding: 100px 0;
  &__inner {}
  &__title {
    color: #222122;
  }
}

.calculator {
  background: #FFFFFF;
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  overflow: hidden;
  &__header {
    padding: 25px;
    background: #222122;
    font-size: 40px;
    color: #FFFFFF;
    text-align: center;
  }
  &__inner {
    padding: 50px 220px 50px 110px;
  }
  &__row {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;
  }
  &__label {
    font-size: 28px;
    color: #222122;
    flex: 0 0 40%;
    width: 40%;
  }
  &__input {
    max-width: none;
    flex-grow: 1;
    padding: 13px 30px;
    border: 1.5px solid #D0D0D0;
    border-radius: 5px;
    & + & {
      width: 15%;
      margin-left: 30px;
    }
    &::placeholder {
      font-size: 18px;
      line-height: 1.2;
      color: rgb(208, 208, 208);
      transition: .25s;
    }
    &:focus::placeholder {
      color: rgba(208, 208, 208, 0);
    }
  }
  &__checkbox:checked + &__cb-custom {
    border: 2px solid #222122;
  }
  &__checkbox:checked + &__cb-custom::after {
    // background-color: red;
    display: block;
  }
  &__cb-custom {
    width: 50px;
    height: 50px;    
    border: 2px solid #D0D0D0;
    border-radius: 5px;
    margin-left: 0;
    margin-right: auto;
    cursor: pointer;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      display: none;
      top: 8px;
      left: 8px;
      background: no-repeat center / contain;
      background-image: url('../img/icon/tick.svg');
      width: 30px;
      height: 30px;
    }
  }

  &__checkbox {
    display: none;
  }
  &__result-price {
    flex: 0 0 40%;
    width: 40%;
  }
  &__result-title {
    font-weight: 500;
    font-size: 28px;
    color: #222122;
    display: inline-block;
    margin-right: 30px;
  }
  &__result-output {
    font-weight: 700;
    font-size: 30px;
    color: #C7150C;
    display: inline-block;
  }
  &__result-period {}
  &__btns {
    width: 100%;
    padding-left: 40%;
  }
  &__btn {
    // margin-left: 40%;
  }
  &__reset {
    border: none;
    background-color: transparent;
    cursor: pointer;
    color: #C7150C;
    &:hover {
      background-color: transparent;
      color: $color-accent-bright;
    }
    &:active {
      background-color: transparent;
    }
  }
  &__geo {
    & .geo__link {
      font-size: 24px;
    }
  }
}


@media (max-width: 1200px) {
  .calculator {
    &__inner {
      padding: 50px 100px 50px 40px;
    }
  }
}
@media (max-width: 1024px) {
  .calculator {
    &__label {
      flex-basis: 35%;
      width: 35%;
    }
    &__result-price {
      flex-basis: 35%;
      width: 35%;
    }
    &__btns {
      padding-left: 35%;
    }
  }
}
@media (max-width: 960px) {
  .calculator {
    &__inner {
      padding: 50px;
    }
    &__row {
      flex-direction: column;
    }
    &__label {
      flex-basis: 100%;
      width: 100%;
      text-align: center;
      margin-bottom: 22px;
    }
    &__input {
      max-width: 500px;
      width: 100%;
      text-align: center;
    }
    &__cb-custom {
      margin: 0 auto;
    }
    &__input + &__input {
      width: 100%;
      margin-left: 0;
      margin-top: 20px;
    }
    &__result-price, &__result-period {
      flex-basis: 100%;
      width: 100%;
      text-align: center;
      margin-bottom: 25px;
    }
    &__btns {
      padding-left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &__btn {
      max-width: 500px;
      margin-bottom: 10px;

    }
  }
}
@media (max-width: 767px) {
  .delivery {
    padding-top: 50px;
  }
  .calculator {
    &__header {
      font-size: 30px;
      padding: 20px;
    }
    &__inner {
      padding: 30px;
    }
  }
}
@media (max-width: 480px) {
  .calculator {
    &__header {
      font-size: 20px;
    }
    &__label {
      font-size: 20px;
      margin-bottom: 15px;
    }
    &__row {
      margin-bottom: 40px;
    }
    &__input {
      font-size: 16px;
      & + & {
        margin-top: 10px;
      }
    }
    &__btn {
      font-size: 20px;
    }
    &__reset {
      font-size: 20px;
    }
    &__geo {
      & .geo__link {
        font-size: 18px;
      }
    }
  }

}
@media (max-width: 320px) {

}