.top {
  padding: 30px 0;
  &__inner {
    display: flex;
    justify-content: space-between;
  }
  &__content {
    padding-top: 150px;
  }
  &__title {
    font-weight: 700;
    font-size: 60px;
    max-width: 600px;
    margin-bottom: 30px;
  }
  &__subtitle {
    font-size: 25px;
    margin-bottom: 60px;
  }
  &__btn {

  }
  &__img {
    max-width: 660px;
    position: relative;
  }
  &__price {
    position: absolute;
    font-size: 50px;
    font-weight: 700;
    left: 25%;
    top: 45%;
    &::after {
      content: '';
      display: inline-block;
      margin-left: 5px;
      background: no-repeat center / contain;
      height: .7em;
      width: 1em;
      background-image: url('../img/icon/rouble-red.svg');
    }
  }
}

@media (max-width: 1280px) {
  .top {
    &__content {
      padding-top: 100px;
    }
  }
}

@media (max-width: 1024px) {
  .top {
    &__inner {
      flex-direction: column;
    }
    &__content {
      position: relative;
      z-index: 2;
    }
    &__title {
      font-size: 70px;
      max-width: 650px;
    }
    &__subtitle {
      font-size: 35px;
    }
    &__btn {
      font-size: 35px;
    }
    &__img {
      margin-right: 0;
      margin-left: auto;
      position: relative;
      z-index: 1;
      margin-top: -200px;
      width: 80%;
      max-width: none;
    }
  }
}
// @media (min-width: 992px) and (max-width: 1199px) {

// }
// @media (min-width: 768px) and (max-width: 991px) {

// }
@media (max-width: 767px) {
  .top {
    &__content {
      padding-top: 60px;
    }
    &__title {
      font-size: 50px;
      max-width: 530px;
    }
    &__img {
      margin-top: -130px;
    }
    &__price {
      // position: absolute;
      // font-size: 50px;
      // font-weight: 700;
      font-size: 30px;
      left: -10%;
      top: 85%;
      &::after {
        // content: '';
        // display: inline-block;
        // margin-left: 5px;
        // background: no-repeat center / contain;
        // height: .7em;
        // width: 1em;
        // background-image: url('../img/icon/rouble-red.svg');
      }
    }
  }
}
@media (max-width: 480px) {
  .top {
    &__content {
      // padding-top: 60px;
    }
    &__title {
      font-size: 30px;
      max-width: 330px;
    }
    &__subtitle {
      font-size: 18px;
    }
    &__btn {
      font-size: 20px;
    }
    &__img {
      margin-top: -100px;
    }
  }
}
@media (max-width: 320px) {

}