.contacts {
  padding: 100px 0;
  &__inner {}
  &__title {
    color: #222122;
  }
  &__row {
    display: flex;
    align-items: flex-start;
  }
  &__box {
    display: flex;
    margin-right: 175px;
  }
  &__icon {
    width: 40px;
    height: 40px;
    margin-right: 30px;
  }
  &__list {}
  &__item {}
  &__item--more {
    margin-top: 25px;
    font-weight: 500;
  }
  &__socials {
    & .socials__link {
      display: block;
      width: 40px;
      height: 40px;
    }
    & .socials__icon {
      width: 40px;
      height: 40px;
    }
    & .socials__link + .socials__link {
        margin-left: 30px;
    }
  }
}


@media (max-width: 1280px) {
  .contacts {
    &__row {
      justify-content: space-between;
    }
    &__box {
      margin-right: 0;
    }
  }
}

@media (max-width: 1024px) {
  .contacts {
    font-size: 20px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {

}
@media (min-width: 768px) and (max-width: 991px) {

}
@media (max-width: 767px) {
  .contacts {
    padding-top: 75px;
    padding-bottom: 60px;
    &__row {
      flex-direction: column;
    }
    &__box {
      // width: 100%;
      margin-bottom: 60px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &__icon {
      // width: 40px;
      // height: 40px;
    }
  }
}
@media (max-width: 480px) {

}
@media (max-width: 320px) {

}