.footer {
  padding: 90px 0;
  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }
  &__nav {}
  &__nav-list {
  
  }
  &__nav-item {
    font-size: 18px;
    color: rgb(255, 255, 255);
    transition: .15s;
    margin-bottom: 5px;
    &:hover {
      color: rgba(255, 255, 255, 0.75);
    }
  }
  &__nav-link {

  }
  &__logo {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  &__btn {
    color: #ffffff;
    border-color: #ffffff;
  }
}

@media (max-width: 1024px) {
  .footer {
    &__btn {
      display: none;
    }
  }
}
@media (min-width: 992px) and (max-width: 1199px) {

}
@media (min-width: 768px) and (max-width: 991px) {

}
@media (max-width: 767px) {
  .footer {
    &__nav {
      display: none;
    }
  }
}
@media (max-width: 480px) {

}
@media (max-width: 320px) {

}