*,*::before,*::after {box-sizing: border-box;}
ul,ol {padding: 0;}
body,h1,h2,h3,h4,p,ul,ol,li,figure,figcaption,blockquote,dl,dd {margin: 0;}
body {min-height: 100vh;scroll-behavior: smooth;text-rendering: optimizeSpeed;line-height: normal;}
ul,ol {list-style: none;}
a {text-decoration: inherit;color: inherit;}
a:not([class]) {text-decoration-skip-ink: auto;}
img {max-width: 100%;display: block;}
input,button,textarea,select {font: inherit;}
@media (prefers-reduced-motion: reduce) {* {animation-duration: 0.01ms !important;animation-iteration-count: 1 !important;transition-duration: 0.01ms !important;scroll-behavior: auto !important;}}
